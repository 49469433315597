import './App.css';

function Header(){

    return (
        <header className="App-header">
            <div>
                <label><img width="25px" src='./rocket-launch-lines.png' alt="Logo"></img>The Quick NFT</label>
            </div>
        </header>
        
    );
}

export default Header;